.aboutme-container{
    min-height: 100vh;
  
    }

.aboutme-section{
    min-height: 100vh;
    background-color: #1c2e3f;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)) ;
    /* animation: 50s bgrandom infinite; */
    padding-top: 200px;
    padding-bottom: 200px;

}

.aboutmeanimated {
    border-color: white;
    border-style: solid;
    border-left: 4;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
}

.profile-picture{
    width:300px;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
}

.aboutme-paragraph{
    text-align: justify;   
}
.aboutme-paragraph-span{
    font-family: "sans-serif";
    font-size: 20px;
}

.aboutme-row{
    min-height: 100vh;
}

.aboutme-video{
    width: 300px;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
    filter: brightness(0.6);
}

.section-tittle{
    color: #FFE699;
    font-family: "sans-serif";
}
.section-tittle-span{
    font-size: 40px;
}
.button-about1{
    color: #FFE699 !important;
    border-color: #FFE699 !important;
    margin-left: 16px;
}
.button-about1:hover {
    color: #000000 !important;
    border-color: #FFE699 !important;
    background-color: #FFE699 !important;
}
.button-about2 {
    border-color: #FFE699 !important;
    background-color: #FFE699 !important;
}
.button-about2:hover {
    border-color: #FFE699 !important;
    background-color: #f8e7b3 !important;
}

@media (max-width: 640px) {

    .aboutme-img-div .aboutme-overlay .aboutme-video {

        display: none !important;

    }
    .aboutme-section {
        padding-top: 60px;
        padding-bottom: 10px;

    }
    .aboutmeanimated{
        border-width: 0;
        padding-right: 24px !important; 
        margin-top: 0 !important;
    }
    .button-about1 {
        margin-left: 0;
    }


}