.projects-container {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 300px;  
}
.card-title-projects{
    font-family: "sans-serif";
    font-size: 22px;
 
}
.projects-section {

    background-color: #1c2e3f;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7))
}

.projects-gotodiv {
    display: flex;
    position: absolute;
    bottom: 0px;
}


.projects-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    animation: fadeslow 0.8s ease forwards;
    z-index: 1;
}

.video-project{
    transition: all 0.9s ease;
    cursor:default;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.projects-paragraph{
    text-align: justify;
}
.project-text{
    cursor: default;
    animation: opacitytextvideo 0.8s ease forwards;
    opacity: 0;
    z-index: 2;
}
.githublink-video{
    cursor: pointer;
    color: white;
    font-size: 36px 
}
.weblink-video {
    cursor: pointer;
    color: white;
    margin-left: 15px;
    margin-top: 2px;
}
.video-scale{ 
    scale: 1.1;
}
.githublink-video:hover{
    scale: 1.1;
}
.weblink-video:hover {
    scale: 1.1;
}

@keyframes fadeslow {
    0% {
        background-color: rgba(0, 0, 0, 0.0);
        scale: 1;
    }
    100% {
        background-color: rgba(0, 0, 0, 0.7);
        scale: 1.1;
    }
}
@keyframes opacitytextvideo {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 640px) {

    .projects-container{
        padding-top: 100px;
        padding-bottom: 150px;
    }
    .project-title-div{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .section-tittle{
        padding-left: 12px;
    }
    .card-display-none-xs{
        display: none;
    }
    .projects-overlay, .video-project {
        height: 175px;
    }
    .weblink-video{
        padding-top: 1px;
        font-size: 30px;
    }
.card-title-projects{
    margin-top: 10px;
}
    .projects-gotodiv {

        bottom: 5px;
    }


}