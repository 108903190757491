
.section-header{
        height: 100vh;    
}
.container-header{
        padding: 0 !important;
        width: 100%;
}

.header-video {
        width: 100%;
        height:100vh;
        object-fit: cover;
        margin: 0;
        padding: 0;
}

.overlayimage{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: rgba(13, 22, 29, 0.6)

}

.header-content{
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: 2px 2px 2px #000000;

        
}
.header-paragraph{
        
        text-align: center;
        font-size: 35px;
        margin-top: 10px;
        padding: 0;
        margin-bottom: 40px;
        font-family:  "sans-serif";
        text-shadow: 2px 2px 2px #000000;
        color: #ffffff;
        border-width: 2px;
        opacity: 0;
        animation: showopacity 3.8s ease-in 1s forwards;
    
}
.header-tittle{

        /* font-family: 'Times New Roman', Times, serif;
        font-family: 'Old Standard TT', serif; */
        font-family: 'Cinzel', serif;
        text-shadow: 2px 2px 2px #000000;
        font-size: 150px;
        margin: 0;
        padding: 0;
        color: #FFE699; 
        line-height: 1;
        opacity: 0;
        animation: showopacity2 4s ease-in 2s forwards;
       
}
.header-tittle-sub{
        font-size: 110px; 
}


.header-button{
        border-radius: 100% !important;
        margin-left: 10px;
        margin-right: 10px;
        height: 60px;
        width: 60px;
        box-shadow:2px 2px 4px #000000;
        text-shadow: 2px 2px 4px #000000;
        opacity: 0;
        animation: siceup 0.8s ease-out 4s; 
        animation-fill-mode: forwards;
}

.header-content-none {
        display: none;
}

@keyframes showopacity {
        0% {
        opacity: 0.2;
        transform: scaley(0.05);
        }
        60% {
        opacity: 0.2;
        transform: scaley(0.05);
        }
        70% {
        opacity: 0.2;
        transform: scaley(0.05);
        }
        80% {
        opacity: 0.3;
        transform: scaley(1.05);
      
        }
        90% {
        transform: scaley(1);
        }
        100% {
        opacity: 1; 
       
        
        }
}
@keyframes siceup {
        0% {
                transform: scale(0);  
                opacity: 0;
        }
        80% {
                transform: scale(1.2);
                opacity:0.3;
        }
        100% {
                transform: scale(1);
                opacity: 1;
        }

}

@keyframes showopacity2 {
        0% {  
                opacity: 0;
        }

        100% {
                opacity: 1;
        }
}

@media (max-width: 640px) {
        .header-tittle{
                font-size: 46px;
        }
        .header-tittle-sub{
                font-size: 36px;
        }
        .header-paragraph{
                font-size: 18px;
                margin-bottom: 15px;
        }
        .header-button{
                height: 40px;
                width: 40px;
                margin-left: 6px;
                margin-right: 6px;
        }
        .header-icons{
                position: relative;
                top: -4px;
                left: -4px;
        }
}