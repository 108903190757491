.navar-content{
    background: rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(4px) !important;
    border-bottom: 1px solid  !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
    height: 80px !important;
    width: 100% !important;
   
}


.nav-link{
    color: white !important;
    font-size: 14px !important;
}

.active{
    color: rgb(153, 148, 148) !important;
}

.nav-link:hover {
    color: rgb(192, 190, 190) !important;
}

.navar-content-start {
    background: rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    border: none !important;
    height: 80px ;
    width: 100% !important;
    
    
}
nav{
    transition: 3s;
    animation: up-down 4.8s ease-in forwards;
}


@keyframes up-down {
    from {
        transform: translateY(-400px);
    }
    to {
        transform: translateY(0);
    }
}

@media (max-width: 640px) {

    .nav-custom {

        

    }


}