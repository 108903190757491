.services-container {
    min-height: 100vh;
}

.services-section {
    min-height: 100vh;
    background-color: #1c2e3f;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
    padding-bottom: 200px;
    padding-top: 200px;
}

.services-row {
    min-height: 100vh;
}

.services-paragraph{
    text-align: justify;
}

.card-servises{
    height: 260px;
    object-fit: cover;  
}

.services-overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    background-color: rgba(8, 32, 53, 0.8);
    border-radius: 5px;
}
.services-overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    background-color: rgba(46, 46, 7, 0.8);
    border-radius: 5px;
}
.services-overlay3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    background-color: rgba(22, 7, 29, 0.8);
    border-radius: 5px;
}
.services-overlay4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    background-color: rgba(7, 42, 8, 0.8);
    border-radius: 5px;
}

.services-icon{
    font-size: 60px;
}

.card-text{
    text-align: justify;
}
.servises-p-card {
    display: block;
}
.services-paragraph {
    margin-bottom: 50px !important;
}

@media (max-width: 640px) {



    .services-section {
        padding-top: 0px;
        padding-bottom: 20px;

    }
    .services-div-animated{
        padding-left: 15px;
        
    }
    .servises-p-card{
        display: none;
    }
    .card-servises {
        height: 170px;
    }
    .services-overlay1, .services-overlay2, .services-overlay3, .services-overlay4 {
        height: 170px;

    }
    .card-img-overlay{
        padding: 5px !important;
        padding-left: 10px !important;
    }
    .services-paragraph{
        margin-bottom: 20px !important;
    }



}